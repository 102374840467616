import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { karantina, roboto } from "@/utis/fonts";
import Image from "next/image";
import { useTranslations } from "next-intl";
import Marquee from "react-fast-marquee";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ICON_FILE_NAME_LIST } from "@/constants/IconsFileName";
import LoginPopup from "../Login/LoginPopup";
import { LoginType } from "../Login/LoginPopup/common";

const StyledWrapper = styled(Stack)(
  ({ theme }) => `
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  background: ${theme.palette.customBackground.cards};
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding-top: ${theme.spacing(10)};
  padding-left: ${theme.spacing(5)};
  padding-right: ${theme.spacing(5)};
  padding-bottom: ${theme.spacing(8)};
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding-top: ${theme.spacing(8)};
    padding-bottom: ${theme.spacing(5)};
  }
`,
);

const StyledText = styled(Box)(
  ({ theme }) => `
  font-size: 80px;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  color: ${theme.palette.customBackground.custom};
  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 56px;
  }
`,
);

const StyledButton = styled(Button)(
  ({ theme }) => `
  height: 60px;
  width: fit-content;
  background-color: ${theme.palette.text.primary};
  padding-inline: ${theme.spacing(2)};
  font-weight: 800;
  color: ${theme.palette.primary.main};
  text-transform: none;
  margin-top: ${theme.spacing(6)};
  @media (max-width: ${theme.breakpoints.values.md}px) {
    height: 44px;
  }
`,
);

const StyledGalleryText = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
  font-size: 24px;
  margin-top: ${theme.spacing(15)};
  margin-bottom: ${theme.spacing(5)};
  font-family: ${roboto.style.fontFamily};
  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 16px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }
`,
);

const StyledGalleryWrapper = styled(Stack)(() => ({
  alignItems: "flex-start",
  width: "100%",
  "& .rfm-marquee": {
    minWidth: "unset",
  },
}));

const StyledImageRow = styled(Stack)(
  ({ theme }) => `
    flex-direction: row;
    margin-top: ${theme.spacing(4)};
    position: relative;
`,
);

const StyledImage = styled(Image)(
  ({ theme }) => `
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        margin-left: ${theme.spacing(6)};
        border-radius: 5px;
    `,
);

gsap.registerPlugin(ScrollTrigger);


function WordSpan(chunks: React.ReactNode) {
  return <span className="tools-word">{chunks}</span>;
}

function ExploreTools() {
  const theme = useTheme();
  const t = useTranslations("Home");
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
  const openLoginPopup = useCallback(() => {
    setShowLoginPopup(true);
  }, []);

  const closeLoginPopup = useCallback(() => {
    setShowLoginPopup(false);
  }, []);

  useEffect(() => {
    const triggerElem = triggerRef.current;
    const words = gsap.utils.toArray("span.tools-word") as HTMLElement[];

    if (triggerElem) {
      words.forEach((word, index) => {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: word,
              start: "bottom bottom",
              end: "bottom center",
              scrub: true,
            },
          })
          .to(word, {
            color: theme.palette.text.primary,
            duration: 2.5,
          });
      });
    }
  }, [theme.palette.text.primary]);

  return (
    <Box>
      <StyledWrapper ref={triggerRef}>
        <Box sx={{ textTransform: "uppercase", textAlign: "center", px: isMobile ? 2 : 4, maxWidth: "100%" }}>
          <StyledText className={karantina.className} component="span">
            {isMobile
              ? t.rich("explore_title_mobile", { span: WordSpan })
              : t.rich("explore_title", { span: WordSpan })}
          </StyledText>
        </Box>
        <StyledButton
          variant="contained"
          size="large"
          onClick={openLoginPopup}
          fullWidth={false}
          color="secondary"
          className={roboto.className}
        >
          {t("explore_btn")}
        </StyledButton>

        <LoginPopup open={showLoginPopup} onClose={closeLoginPopup} loginType={LoginType.SIGN_UP} />
        <StyledGalleryText>{t("explore_desc")}</StyledGalleryText>
        <StyledGalleryWrapper>
          <Box>
            {Array.from({ length: 3 }).map((_, index) => (
              <Marquee direction={index % 2 === 0 ? "right" : "left"} key={index}>
                <StyledImageRow key={index}>
                  {ICON_FILE_NAME_LIST.sort(() => Math.random() - 0.5).map((icon) => (
                    <StyledImage src={`/betsites/icons/${icon}`} alt={icon} width={48} height={48} key={`${index}_${icon}`} />
                  ))}
                </StyledImageRow>
              </Marquee>
            ))}
          </Box>
        </StyledGalleryWrapper>
      </StyledWrapper>
    </Box>
  );
}

export default ExploreTools;