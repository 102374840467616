import React, { useEffect, useState, useRef } from "react";
import { Box, Modal, styled, useMediaQuery } from "@mui/material";
import { useTranslations } from "next-intl";
import { useTheme } from "@/theme/ThemeContext";
import Image from "next/image";
import { ReadonlyURLSearchParams, useSearchParams } from "next/navigation";
import { getContentByUTMSource } from "./UTMContent";
import logoAnnimation from "./logo_annimation.gif";
import UTMContentRenderer from "./UTMContentRenderer";

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.modal.background};
    border: 1px solid ${theme.palette.modal.border};
    backdrop-filter: blur(4px);
    border-radius: 30px;
    padding: ${theme.spacing(3)};
    min-width: 600px;
    min-height: 700px;
    max-width: 600px;
    max-height: 100vh;
    overflow: auto;

    ${theme.breakpoints.down("md")} {
        min-width: 300px;
        min-height: 600px;
        max-width: 90%;
        max-height: 80vh;
        padding: ${theme.spacing(2)};
    }
  `,
);

const checkForUTMParameters = (params: ReadonlyURLSearchParams) =>
  params.has("utm_source") || params.has("utm_medium") || params.has("utm_campaign");

function UTMModal() {
  const [open, setOpen] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const t = useTranslations("utm");
  const params = useSearchParams();

  const utmSource = params.get("utm_source");

  const fadeOutTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const showAnimationTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (checkForUTMParameters(params)) {
      setOpen(true);
      fadeOutTimeoutRef.current = setTimeout(() => {
        setFadeOut(true);
        showAnimationTimeoutRef.current = setTimeout(() => {
          setShowAnimation(false);
        }, 500);
      }, 1000);
    }

    return () => {
      if (fadeOutTimeoutRef.current) {
        clearTimeout(fadeOutTimeoutRef.current);
      }
      if (showAnimationTimeoutRef.current) {
        clearTimeout(showAnimationTimeoutRef.current);
      }
    };
  }, [params]);

  const content = checkForUTMParameters(params) ? getContentByUTMSource(utmSource ?? "default", t) : null;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledModal open={open} onClose={handleClose}>
      <StyledBox margin="auto" pb={isMobile ? 1 : 3} px={isMobile ? 1.5 : 3}>
        {showAnimation ? (
          <Box
            width="100%"
            alignContent="center"
            justifyContent="center"
            display="flex"
            style={{
              opacity: fadeOut ? 0 : 1,
              transition: "opacity 1s ease-in-out",
            }}
          >
            <Image src={logoAnnimation} width={175} alt="Loading animation" />
          </Box>
        ) : (
          content && (
            <Box
              style={{
                opacity: fadeOut ? 1 : 0,
                transition: "opacity 1s ease-in-out",
              }}
            >
              <UTMContentRenderer content={content} />
            </Box>
          )
        )}
      </StyledBox>
    </StyledModal>
  );
}

export default UTMModal;
