"use client";

import {
  ArbitrageBetting,
  ArbitrageBettingAlt,
  ExploreTools,
  OtherTools,
  PlansAndPayment,
  Top,
  Filters,
  Community,
} from "@/components/WelcomePageSections";
import DataRows from "@/components/WelcomePageSections/DataRows";
import UTMModal from "@/components/UTM/UTMModal";

export default function Page() {
  return (
    <>
      <UTMModal />
      <Top />
      <ExploreTools />
      <PlansAndPayment />
      <ArbitrageBetting />
      <ArbitrageBettingAlt />
      <DataRows />
      <Filters />
      <Community />
      <OtherTools />
    </>
  );
}
