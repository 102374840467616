export const ICON_FILE_NAME_LIST: string[] = [
  "18bet.webp",
  "1xbet.webp",
  "4casters.webp",
  "888sport.webp",
  "ballybet.webp",
  "bet105.webp",
  "bet365.webp",
  "bet99.webp",
  "betano.webp",
  "betano_uk.webp",
  "betcris.webp",
  "betfred.webp",
  "betjack.webp",
  "betobet.webp",
  "betonline.webp",
  "betopenly.webp",
  "betplays.webp",
  "betregal.webp",
  "betrivers.webp",
  "betus.webp",
  "betvictor.webp",
  "betway_us.webp",
  "betwhale.webp",
  "bluebet.webp",
  "bodog.webp",
  "bookmaker.webp",
  "bovada.webp",
  "bwin.webp",
  "caesars.webp",
  "circa.webp",
  "clutchbet.webp",
  "coolbet.webp",
  "dexsport.webp",
  "draftkings.webp",
  "espnbet.webp",
  "everygame.webp",
  "fanatics.webp",
  "fanduel.webp",
  "fliff.webp",
  "goldennugget.webp",
  "hardrock.webp",
  "justbet.webp",
  "ladbrokes.webp",
  "leovegas.webp",
  "lowvig.webp",
  "mgm.webp",
  "mybookie.webp",
  "neobet.webp",
  "northstarbets.webp",
  "novig.webp",
  "overtimemarkets.webp",
  "parimatch.webp",
  "partypoker.webp",
  "pinnacle.webp",
  "playfallsview.webp",
  "playnow.webp",
  "pointsbet.webp",
  "primesports.webp",
  "prolineplus.webp",
  "prophetexchange.webp",
  "ps3838.webp",
  "puntnow.webp",
  "rebet.webp",
  "shuffle.webp",
  "sportinteraction.webp",
  "sportsbettingag.webp",
  "sporttrade.webp",
  "sportzino.webp",
  "stake.webp",
  "stx.webp",
  "sugarhouse.webp",
  "superbook.webp",
  "talksportbet.webp",
  "thescore.webp",
  "thrillzz.webp",
  "tipico.webp",
  "ubet.webp",
  "unibet.webp",
  "virginbet.webp",
  "windcreek.webp",
  "wynnbet.webp",
  "xbet.webp",
  "onyxodds.webp",
  "titanplay.webp",
  "parlayplay.webp",
  "prizepicks.webp",
  "underdogsports.webp",
];
