import React, { ComponentProps } from "react";
import { styled } from "@mui/material";
import Image from "next/image";

const StyledImageVal = styled(Image)`
  border-radius: 6px;
`;

function StyledImage({ ...restProps }: ComponentProps<typeof StyledImageVal>) {
  return <StyledImageVal sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" {...restProps} />;
}

export default StyledImage;
