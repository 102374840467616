import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function RedChartUptrendIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                d="M2 6c0-2.20914 1.79086-4 4-4h20c1.6999 0 3.1521 1.06033 3.7313 2.55565l-.5852 1.77671.8539.84542v18.82222c0 2.2091-1.7909 4-4 4h-20c-1.88412 0-3.46398-1.3027-3.88808-3.0565l.6703-1.8923-.78222-.7108z"
                fill="#e1d8ec"
            />
            <path
                d="M10 11v10h-8v1h8v8h1v-8h10v8h1v-8h8v-1h-8v-10h8v-1h-8v-8h-1v8h-10v-8h-1v8h-8v1zm1 0h10v10h-10z"
                fill="#b4acbc"
            />
            <path
                d="M2.11995 26.9761c-.07835-.3124-.11995-.6394-.11995-.9761v-1.7012l7.06209-6.9727c.84504-.8344 2.19971-.8475 3.06081-.0297l2.1482 2.0405c.1968.1869.5068.1827.6985-.0096l14.752-14.79654c.1797.45483.2784.95049.2784 1.46921v1.18705l-13.8682 13.71458c-.8412.8318-2.1895.8496-3.0522.0401l-2.1499-2.017c-.1962-.1841-.5029-.1799-.694.0095z"
                fill="#f92f60"
            />
        </SvgIcon>
    );
}

export default RedChartUptrendIcon;
