import { Box, IconButton, Modal, styled } from "@mui/material";
import React, { ComponentProps } from "react";
import CloseRoundedIcon from "../Icons/CloseRoundedIcon";

const StyledBox = styled(Box)(
  ({ theme }) => `
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.modal.background};
    border: 1px solid ${theme.palette.modal.border};
    backdrop-filter: blur(4px);
    border-radius: 30px;
    padding: ${theme.spacing(2)};
    margin: auto;
    max-width: 60%;
    position: relative;
    overflow: auto;
    max-height: 90vh;
    display: block;
    overflow-y: auto;
    
    ${theme.breakpoints.down("md")} {
      margin-top: 12px;
      max-width: 90%;
    }

    hr {
      border-color: ${theme.palette.border.secondary};
      border-style: solid;
      border-width: 0;
      border-bottom-width: thin;
    }
  `,
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
    position: absolute;
    background-color: ${theme.palette.grayScale.sixpercent};
    top: ${theme.spacing(1.5)};
    right: ${theme.spacing(1.5)};
  `,
);

type InfoModalModalProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  hideCloseBtn?: boolean;
} & ComponentProps<typeof Box>;

function InfoModalModal({ open, onClose, children, hideCloseBtn, ...restProps }: InfoModalModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        // Align this Center Vertically and Horizontally.
        display: "flex",
        backgroundColor: "rgba(0, 0, 0, 0.2)"
      }}
    >
      <StyledBox alignSelf="center" height="max-content" width="max-content" margin="auto" {...restProps}>
        {hideCloseBtn && (
          <StyledIconButton onClick={onClose} disableRipple disableTouchRipple>
            <CloseRoundedIcon color="secondary" sx={{ fontSize: 9 }} />
          </StyledIconButton>
        )}
        {children}
      </StyledBox>
    </Modal>
  );
}

export default InfoModalModal;
